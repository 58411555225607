import { constants } from '../constants';

const initialState = {
    user: {},
    loading: true,
    token: null,
    countryCode: 'QA',
    countryList: null,
    categoryList: null,
    userData: null,
    cartCount: 0,
    cartArray: null,
    cartData: null,
    countryWarning: false,
    serviceClick: 0,
    lang:'ar'
};

export function user(state = initialState, action) {
    switch (action.type) {
        case constants.REQUEST:
            return {
                ...state,
                loading: action.value
            };
        case constants.LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                user: action.user.user,
                token: action.user.token
            };
        case constants.SET_COUNTRY_CODE:
            return {
                ...state,
                countryCode: action.value ? action.value : '',
                countryWarning: false,
                userData: null
            };
        case constants.SET_COUNTRY_LIST:
            return {
                ...state,
                countryList: action.value
            };
        case constants.SET_CATEGORY_LIST:
            return {
                ...state,
                userData: action.value
            };
        case constants.SET_CARTCOUNT:
            return {
                ...state,
                cartCount: action.value
            };
        case constants.SET_CARTARRAY:
            return {
                ...state,
                cartArray: action.value
            };
        case constants.REMOVE_ITEM_CARTARRAY:
            return {
                ...state,
                cartArray: state.cartArray ? state.cartArray.filter(a => a != action.value) : []
            };
        case constants.SET_CARTDATA:
            return {
                ...state,
                cartData: action.value,
                cartCount: action.value ? action.value.cartItemList ? action.value.cartItemList.length : null : null,
                cartArray: SetCartArray(action.value, state)
            };
        case constants.SET_COUNTRY_WARNING:
            return {
                ...state,
                countryWarning: action.value
            };
        case constants.GOTO_SERVICE_DETAIL:
            debugger
            return {
                ...state,
                serviceClick: state.serviceClick ? state.serviceClick++ : 1
            };
        case constants.SET_LANGUAGE:
            return {
                ...state,
                lang: action.value
            }
        default: return state
    }
}

function SetCartArray(data, state) {
    let thisarray = [];
    if (data) {
        if (data.cartItemList) {
            data.cartItemList.map(a => {
                let thisprice = a.prices.filter(x => x.isSelected);
                if (thisprice.length > 0) {
                    if (!(thisarray.includes(thisprice[0].subjectPriceID))) {
                        thisarray.push(thisprice[0].subjectPriceID);
                    }
                }
            })
        }
    }
    return thisarray;
}